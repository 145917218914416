require("./channels");

import "vanilla-colorful"
import 'vanilla-colorful/hex-input.js';

import "chartkick/chart.js"

import "@rails/request.js";
import "@hotwired/turbo-rails";
import "trix";
import "@rails/actiontext";

import "./controllers";
import consumer from "./channels/consumer";

import "./turbo_streams"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

// Preserve scroll position in sidebar
function preserveScroll () {
  let sidebar = document.querySelector(".sb");
  if (sidebar) {
    sessionStorage.setItem("sidebarScroll", sidebar.scrollTop);
  }
}

function restoreScroll (event) {
  let storedScrollPosition = sessionStorage.getItem("sidebarScroll");
  if (storedScrollPosition !== null) {
    let sidebar = document.querySelector(".sb");
    if (sidebar) {
      sidebar.scrollTop = Number(storedScrollPosition);
    }
  }
}

window.addEventListener("turbo:before-cache", preserveScroll)
window.addEventListener("turbo:before-render", restoreScroll)
window.addEventListener("turbo:render", restoreScroll)
// End of scroll position preservation

document.addEventListener("DOMContentLoaded", function () {
  var div = document.getElementById("payment");
  if (div === null) {
    return;
  }

  let stripe_public_key = document
    .querySelector('meta[name="stripe-public-key"]')
    .getAttribute("content");
  let stripe = Stripe(stripe_public_key);
  stripe
    .redirectToCheckout({
      sessionId: div.getAttribute("data-session-id"),
    })
    .then(function (results) { });
});

document.addEventListener("turbo:load", function () {
  var div = document.getElementById("payment");
  if (div === null) {
    return;
  }

  let stripe_public_key = document
    .querySelector('meta[name="stripe-public-key"]')
    .getAttribute("content");
  let stripe = Stripe(stripe_public_key);
  stripe
    .redirectToCheckout({
      sessionId: div.getAttribute("data-session-id"),
    })
    .then(function (results) { });
});

Turbo.config.forms.confirm = (message, element) => {
  let dialog = document.getElementById("turbo-confirm")
  if (element.dataset.confirmTitle) {
    dialog.querySelector("div#confirm-title").textContent = element.dataset.confirmTitle
  }
  dialog.querySelector("p").textContent = message
  if (element.dataset.confirmButton) {
    if (element.dataset.blue) {
      dialog.querySelector("button#confirm-button").classList.remove("bg-rred-400")
      dialog.querySelector("button#confirm-button").classList.remove("hover:bg-rred-350")
      dialog.querySelector("button#confirm-button").classList.add("bg-bblue-300")
    } else {
      dialog.querySelector("button#confirm-button").classList.add("bg-rred-400")
      dialog.querySelector("button#confirm-button").classList.add("hover:bg-rred-350")
      dialog.querySelector("button#confirm-button").classList.remove("bg-bblue-300")
    }
    dialog.querySelector("button#confirm-button").textContent = element.dataset.confirmButton
  }
  dialog.showModal()

  return new Promise((resolve, reject) => {
    dialog.addEventListener("close", () => {
      resolve(dialog.returnValue == "confirm")
    }, { once: true })
  })
}
