import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "item", "searchInput"]

  connect() {
    this.availableOnly = false
  }

  filter(event) {
    const query = event?.target?.value?.toLowerCase() || this.searchInputTarget?.value?.toLowerCase() || ""

    this.itemTargets.forEach(item => {
      const locationName = item.dataset.locationName
      const isActive = item.dataset.active === "true"
      const matchesFilter = !this.availableOnly || !isActive || !item.hasAttribute('data-active')
      const matchesSearch = locationName.includes(query)

      item.classList.toggle("hidden", !matchesSearch || !matchesFilter)
    })
  }

  toggleAvailableOnly(event) {
    this.availableOnly = event.target.checked
    this.filter()
  }
}