import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  filter(event) {
    const searchTerm = event.target.value.toLowerCase()
    const rows = document.querySelectorAll('tr[data-searchable]')

    rows.forEach(row => {
      const text = row.textContent.toLowerCase()
      row.style.display = text.includes(searchTerm) ? '' : 'none'
    })
  }
}