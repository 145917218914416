import { Controller } from "@hotwired/stimulus"
import { put } from '@rails/request.js'

// Connects to data-controller="drag-drop-design"
export default class extends Controller {
  static targets = ["design", "folder"]
  static values = {
    url: String,
    teamId: String,
    userId: String
  }

  connect() {
    this.initializeDragAndDrop()
    this.ghostElements = []
  }

  initializeDragAndDrop() {
    this.designTargets.forEach(design => {
      design.setAttribute('draggable', true)
      design.addEventListener('dragstart', this.handleDragStart.bind(this))
      design.addEventListener('drag', this.handleDrag.bind(this))
      design.addEventListener('dragend', this.handleDragEnd.bind(this))
    })

    this.folderTargets.forEach(folder => {
      folder.addEventListener('dragenter', this.handleDragEnter.bind(this))
      folder.addEventListener('dragover', this.handleDragOver.bind(this))
      folder.addEventListener('dragleave', this.handleDragLeave.bind(this))
      folder.addEventListener('drop', this.handleDrop.bind(this))
    })
  }

  createGhostElements(sourceElement) {
    const collectionId = sourceElement.dataset.collectionId
    const collectionElements = document.querySelectorAll(`[data-collection-id="${collectionId}"]`)

    collectionElements.forEach((element, index) => {
      if (element !== sourceElement) {
        const ghost = document.createElement('div')
        const originalImg = element.querySelector('.p-2 img')
        if (!originalImg) return

        const thumbnail = document.createElement('img')
        thumbnail.src = originalImg.src
        thumbnail.style.width = '100%'
        thumbnail.style.height = '100%'
        thumbnail.style.objectFit = 'contain'
        thumbnail.draggable = false

        ghost.style.position = 'fixed'
        ghost.style.pointerEvents = 'none'
        ghost.style.zIndex = '99999'
        ghost.style.opacity = '0.8'
        ghost.style.width = '60px'
        ghost.style.height = '60px'
        ghost.style.padding = '4px'
        ghost.style.overflow = 'hidden'
        ghost.style.borderRadius = '4px'
        ghost.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)'
        ghost.style.backgroundColor = 'white'
        ghost.classList.add('design-ghost')

        ghost.appendChild(thumbnail)
        document.body.appendChild(ghost)
        this.ghostElements.push(ghost)
      }
    })
  }

  handleDragStart(e) {
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/plain', e.target.dataset.designId)
    e.dataTransfer.setData('collection-id', e.target.dataset.collectionId)

    // Store the initial offset between cursor and element
    const rect = e.target.getBoundingClientRect()
    this.dragOffsetX = e.clientX - rect.left
    this.dragOffsetY = e.clientY - rect.top
    this.draggedWidth = rect.width

    // Add opacity to other cards in collection
    const collectionId = e.target.dataset.collectionId
    document.querySelectorAll(`[data-collection-id="${collectionId}"]`).forEach(element => {
      if (element !== e.target) {
        element.style.opacity = '0.5'
      }
    })

    // Create ghost elements for all designs in collection
    this.createGhostElements(e.target)
  }

  handleDrag(e) {
    if (e.clientX === 0 && e.clientY === 0) return // Ignore invalid coordinates

    // Calculate the actual position of the dragged element's ghost
    const draggedGhostX = e.clientX - this.dragOffsetX
    const draggedGhostY = e.clientY - this.dragOffsetY

    // Position ghost elements in a grid layout
    this.ghostElements.forEach((ghost, index) => {
      const ghostWidth = 60 // width of each ghost
      const ghostHeight = 60 // height of each ghost
      const spacing = 6 // spacing between ghosts
      const itemsPerRow = 2 // wrap after 2 items

      // Calculate row and column position
      const row = Math.floor(index / itemsPerRow)
      const col = index % itemsPerRow

      // Position relative to the right edge of the dragged ghost
      const xOffset = this.draggedWidth + spacing + (col * (ghostWidth + spacing))
      const yOffset = row * (ghostHeight + spacing)

      ghost.style.left = `${draggedGhostX + xOffset}px`
      ghost.style.top = `${draggedGhostY + yOffset}px`
    })
  }

  handleDragEnd(e) {
    // Remove opacity from collection cards
    const collectionId = e.target.dataset.collectionId
    document.querySelectorAll(`[data-collection-id="${collectionId}"]`).forEach(element => {
      element.style.opacity = ''
    })

    // Remove all ghost elements
    this.ghostElements.forEach(ghost => ghost.remove())
    this.ghostElements = []

    this.folderTargets.forEach(folder => {
      folder.classList.remove('bg-blue-100')
      folder.classList.add('bg-white')
      folder.classList.remove('ring-2', 'ring-blue-200')
    })
  }

  handleDragEnter(e) {
    e.preventDefault()
    const folder = e.target.closest('[data-drag-drop-design-target="folder"]')
    if (folder) {
      folder.classList.remove('bg-white')
      folder.classList.add('bg-blue-100', 'ring-2', 'ring-blue-200')
    }
  }

  handleDragOver(e) {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'move'
    const folder = e.target.closest('[data-drag-drop-design-target="folder"]')
    if (folder) {
      folder.classList.remove('bg-white')
      folder.classList.add('bg-blue-100', 'ring-2', 'ring-blue-200')
    }
  }

  handleDragLeave(e) {
    const folder = e.target.closest('[data-drag-drop-design-target="folder"]')
    const relatedFolder = e.relatedTarget?.closest('[data-drag-drop-design-target="folder"]')
    if (folder && folder !== relatedFolder) {
      folder.classList.remove('bg-blue-100')
      folder.classList.add('bg-white')
      folder.classList.remove('ring-2', 'ring-blue-200')
    }
  }

  async handleDrop(e) {
    e.preventDefault()
    const folder = e.target.closest('[data-drag-drop-design-target="folder"]')
    folder.classList.remove('bg-blue-100')
    folder.classList.add('bg-white')
    folder.classList.remove('ring-2', 'ring-blue-200')

    const designId = e.dataTransfer.getData('text/plain')
    const collectionId = e.dataTransfer.getData('collection-id')
    const folderId = folder.dataset.folderId

    try {
      const response = await put(`${this.urlValue}/${collectionId}`, {
        body: JSON.stringify({
          easil_move: {
            folder_id: folderId
          },
          collectionId: collectionId
        }),
        responseKind: "turbo-stream"
      })

      // Remove the design card from the view
      const designCard = document.querySelector(`[data-design-id="${designId}"]`)
      if (designCard) {
        designCard.remove()
      }

      // Also remove any other designs from the same collection
      document.querySelectorAll(`.card_collection_${collectionId}`).forEach(el => el.remove())
    } catch (error) {
      console.error('Error moving design:', error)
    }
  }
}