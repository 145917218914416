import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["inactiveLocations", "buttonText", "roles", "manageButtons", "addAllButton", "filterToggle", "item"]
  static values = { managing: Boolean }

  initialize() {
    // Hide inactive locations on controller initialization
    this.itemTargets.forEach(item => {
      if (item.dataset.active === "false") {
        item.classList.add("hidden")
      }
    })
  }

  toggle() {
    this.inactiveLocationsTarget.classList.toggle("hidden")
    this.managingValue = !this.inactiveLocationsTarget.classList.contains("hidden")

    // Show/hide inactive locations
    this.itemTargets.forEach(item => {
      if (item.dataset.active === "false") {
        item.classList.toggle("hidden", !this.managingValue)
      }
    })

    // Toggle visibility of role badges and manage buttons
    this.rolesTargets.forEach(roles => {
      roles.classList.toggle("hidden", this.managingValue)
    })

    this.manageButtonsTargets.forEach(buttons => {
      buttons.classList.toggle("hidden", !this.managingValue)
    })

    // Toggle filter checkbox and reset it when closing manage mode
    if (this.hasFilterToggleTarget) {
      this.filterToggleTarget.classList.toggle("hidden", !this.managingValue)
      if (!this.managingValue) {
        // Reset checkbox
        const checkbox = this.filterToggleTarget.querySelector('input[type="checkbox"]')
        if (checkbox) {
          checkbox.checked = false
          // Trigger the filter reset
          checkbox.dispatchEvent(new Event('change'))
        }
      }
    }

    // Toggle Add All button if it exists
    if (this.hasAddAllButtonTarget) {
      this.addAllButtonTarget.classList.toggle("hidden", !this.managingValue)
    }

    if (this.inactiveLocationsTarget.classList.contains("hidden")) {
      this.buttonTextTarget.textContent = "Manage Memberships"
    } else {
      this.buttonTextTarget.textContent = "Done"
    }
  }
}