import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="stockcode"
export default class extends Controller {
  static values = {
    stockid: String,
    companyid: String,
  }

  stockidValueChanged() {
    if (this.stockidValue && this.element.value.length == 0) {
      fetch(`/portal/stock/${this.stockidValue}?company_id=${this.companyidValue}`)
        .then(response => response.json())
        .then(data => {
          this.element.value = data.nextcode
        })
    }
  }
}
