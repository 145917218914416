import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form']

  static values = {
    clientSecret: String,
    publishableKey: String,
    returnUrl: String
  }

  connect() {
    const stripe = Stripe(this.publishableKeyValue)
    const elements = stripe.elements({
      clientSecret: this.clientSecretValue,
      appearance: {
        theme: 'stripe',
        variables: {
          colorPrimary: '#2563eb',
          colorBackground: '#ffffff',
          colorText: '#1f2937',
          colorDanger: '#dc2626',
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
          spacingUnit: '4px',
          borderRadius: '8px',
        },
        rules: {
          '.Input': {
            border: '1px solid #e5e7eb',
            boxShadow: '0 1px 2px 0 rgba(0 0 0 0.05)',
            padding: '8px 12px'
          },
          '.Input:focus': {
            border: '1px solid #2563eb',
            boxShadow: '0 0 0 1px #2563eb'
          },
          '.Label': {
            color: '#4b5563',
            fontSize: '0.875rem',
            fontWeight: '500'
          },
          '.Error': {
            color: '#dc2626',
            fontSize: '0.875rem'
          }
        }
      }
    })

    const paymentElement = elements.create('payment', {
      layout: {
        type: 'tabs',
        defaultCollapsed: false
      }
    })
    paymentElement.mount('#checkout-element')

    const form = this.element
    form.addEventListener('submit', async (event) => {
      event.preventDefault()

      const submitButton = form.querySelector('button[type="submit"]')
      submitButton.disabled = true
      submitButton.innerHTML = '<span class="inline-block animate-spin mr-2">↻</span> Processing...'

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required'
      })

      if (error) {
        this.handlePaymentError(error, submitButton)
        return
      }

      if (paymentIntent && paymentIntent.status === 'requires_action') {
        const { error: actionError } = await stripe.handleNextAction({
          clientSecret: this.clientSecretValue
        })

        if (actionError) {
          this.handlePaymentError(actionError, submitButton)
          return
        }

        const { paymentIntent: updatedIntent } = await stripe.retrievePaymentIntent(this.clientSecretValue)

        if (updatedIntent.status === 'succeeded') {
          this.handlePaymentSuccess()
        } else {
          this.handlePaymentError({ message: 'Payment failed. Please try again.' }, submitButton)
        }
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        this.handlePaymentSuccess()
      }
    })
  }

  handlePaymentSuccess() {
    window.location.href = this.returnUrlValue
  }

  handlePaymentError(error, submitButton) {
    console.error('Payment failed:', error)

    submitButton.disabled = false
    submitButton.innerHTML = 'Complete Payment'

    const existingError = this.element.querySelector('.error-message')
    if (existingError) existingError.remove()

    const errorDiv = document.createElement('div')
    errorDiv.className = 'error-message mt-4 p-4 bg-red-50 border border-red-200 rounded-lg text-red-700 text-sm'
    errorDiv.textContent = error.message
    submitButton.parentElement.insertAdjacentElement('beforebegin', errorDiv)
  }
}