import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-update"
export default class extends Controller {
  static targets = ["input", "button", "icon"]

  connect() {
    // Initialize with transparent border
    this.inputTarget.classList.remove("border-gray-300")
    this.inputTarget.classList.add("border-transparent")
  }

  toggle() {
    const hasChanged = this.inputTarget.value !== this.inputTarget.defaultValue
    this.buttonTarget.classList.toggle("hidden", !hasChanged)
    this.iconTarget.classList.toggle("hidden", hasChanged)
  }

  // Add focus handler
  focus() {
    this.inputTarget.classList.remove("border-transparent")
    this.inputTarget.classList.add("border-gray-300")
  }

  // Add blur handler
  blur() {
    this.inputTarget.classList.remove("border-gray-300")
    this.inputTarget.classList.add("border-transparent")
  }
}
