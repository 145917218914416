import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

// Send each file selected to the presigner
// Then it renders out a media row which will handle the upload / refresh
// Connects to data-controller="easil-media-upload-presigner"
export default class extends Controller {
  static targets = ["files"]
  static values = { url: String }

  connect() {
    this.fileList = []
  }

  getFile(id) {
    return this.fileList.find(file => file.id === id)
  }

  selected() {
    Array.from(this.filesTarget.files).forEach(async (file) => {
      // Send the file to the presigner
      const preflightDetails = await post(this.urlValue, { body: { filename: file.name, content_type: file.type }, contentType: "application/json", responseKind: "json" })
      const media = await preflightDetails.json
      // Send the presigned url to the media row, this returns a blank row which then handles the upload
      await post(this.urlValue, { body: { id: media.id, filename: media.filename, file_size: file.size }, responseKind: "turbo-stream" })
      file.id = media.id
      file.url = media.url
      this.fileList.push(file)
    });

    this.filesTarget.value = null;
  }
}
