import { Controller } from "@hotwired/stimulus";

import SlimSelect from "slim-select";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  static targets = ["select"];
  static values = {
    placeholder: { type: String, default: "Search..." },
    allowDeselect: { type: Boolean, default: false },
    searchUrl: String,
    setitemcode: { type: Boolean, default: false },
  };

  connect() {
    let _this = this;

    this.select = new SlimSelect({
      select: this.selectTarget,
      settings: {
        placeholderText: this.placeholderValue,
        allowDeselect: this.allowDeselectValue,
      },
      events: {
        afterChange: (newval) => {
          _this.setitemcode(newval)
        },
        search: async(search, callback) => {
          if (search.length < 3) {
            return []
          }

          const request = new FetchRequest(
            "get",
            `${_this.searchUrlValue}?term=${search}`
          );
          const response = await request.perform();
          if (response.ok) {
            const body = await response.json;
            return body;
          }
        },
      }
    });
  }

  setitemcode(newval) {
    if (this.setitemcodeValue) {
      document.getElementById("input-itemcode").dataset.stockcodeStockidValue = newval[0].value;
    }
  }

  disconnect() {
    this.select.destroy();
  }
}
