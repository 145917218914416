import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-inventory"
export default class extends Controller {
  static targets = ["select"]

  toggle(event) {
    this.selectTarget.classList.toggle("!hidden")
  }
}
