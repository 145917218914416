import { Controller } from "@hotwired/stimulus";
import { post } from '@rails/request.js'

// Connects to data-controller="easil"
export default class extends Controller {
  static targets = ["iframe", "link", "title", "orderprints", "orderprintsbutton", "downloadbutton", "orderdownload", "actionapproval"];
  static values = {
    design: String,
    collection: String,
    team: String,
    digitaltemplates: Array,
    rootPath: String,
    disableProofDownloadPrintCheck: Boolean,
    allowPdfDownloads: Boolean,
    brandManager: Boolean,
    title: String,
    subtitle: String,
    templateCode: String,
    templateSize: String,
    teamToken: String,
    companyId: Number
  };

  // Anything below here is messages TO Easil
  downloadShow(e) {
    e.preventDefault()

    let preventPDF = e.currentTarget.dataset.dl == "true" ? false : true

    this.iframeTarget.contentWindow.postMessage(
      {
        event: "navigator.downloadShow",
        preventPDFDownload: preventPDF
      },
      "*"
    );
  }

  iframeloaded() {
    this.sendClipboard();
  }

  print(e) {
    e.preventDefault();
    let d = {
      event: "navigator.editor.orderCheck",
      allowDownloadProof: !this.disableProofDownloadPrintCheckValue
    }

    this.iframeTarget.contentWindow.postMessage(
      {
        event: "navigator.editor.orderCheck",
        allowDownloadProof: !this.disableProofDownloadPrintCheckValue
      },
      "*"
    );
  }

  renameFile(e) {
    e.preventDefault();
    this.iframeTarget.contentWindow.postMessage(
      {
        event: "portal.design.editDescription"
      },
      "*"
    );
  }

  sendClipboard() {
    const designData = localStorage.getItem("designData");

    if (designData) {
      const data = JSON.parse(designData);
      let pb = {
        event: "portal.design.clipboardContent",
        elements: data.elements,
        sourceDesign: data.sourceDesign
      };

      this.iframeTarget.contentWindow.postMessage(
        {
          event: "portal.design.clipboardContent",
          elements: data.elements,
          sourceDesign: data.sourceDesign
        },
        "*"
      );
    }
  }

  // Anything below here is messages FROM Easil
  async handleMessagesFromEasil(evt) {
    if (evt.data == "navigate.portal.addToCart") {
      let url = `/${this.companyIdValue}/teams/${this.teamValue}/diy/v2/order/${this.designValue}/new?editor=1`;
      window.location = url;
    } else if (evt.data.event == "portal.render.completed") {
      if (evt.data.status == "COMPLETED") {
        this.linkTarget.href = evt.data.location;
        this.linkTarget.click();
      }
    } else if (evt.data.event == "portal.copyElements") {
      const designData = JSON.stringify(evt.data);
      localStorage.setItem("designData", designData);
    } else if (evt.data.event == "portal.render.requested") {
      let enqueueDownloadUrl = `/${this.companyIdValue}/diy/teams/${this.teamValue}/download-file-queue`
      const renderData = {
        diy_download_file_queue: {
          diy_download_files_attributes: [
            {
              bleed: evt.data.bleed,
              design_data_id: evt.data.designDataId,
              design_id: evt.data.designId,
              individual_files: evt.data.downloadAsIndividualFiles,
              export_to_my_images: evt.data.exportToMyImages,
              pages: evt.data.pages,
              proof_stamped: false,
              quality: evt.data.quality,
              render_type: evt.data.format,
              movie_speed: evt.data.speed,
              gif_speed: evt.data.gifSpeed,
              template_code: this.templateCodeValue,
              template_size: this.templateSizeValue,
              title: this.titleValue,
              subtitle: this.subtitleValue,
            }
          ]
        }
      }
      const response = await post(enqueueDownloadUrl, { body: JSON.stringify(renderData), headers: { 'Content-Type': 'application/json' }, responseKind: "turbo-stream" })
    } else if (evt.data.event == "portal.design.titleUpdate") {
      this.titleTarget.innerHTML = evt.data.design.title;
    } else if (evt.data.event == "portal.approvalRequestSubmitted") {
      let designData = evt.data.design;

      // load the download and order button state
      let orderDownloadUrl = `/${this.companyIdValue}/teams/${designData.teamId}/diy/v2/collection/${designData.collectionId}/designs/${designData.id}/download_order_buttons?brand_manager=${this.brandManagerValue}&requires_approval=${designData.requiresApproval}&status=PENDING_APPROVAL&template_code=${designData.templateCode}`
      if (this.hasOrderdownloadTarget) {
        this.orderdownloadTarget.src = orderDownloadUrl
      }
    } else if (evt.data.event == "portal.approvalRequestApproved") {
      let designData = evt.data.design;

      let orderDownloadUrl = `/${this.companyIdValue}/teams/${designData.teamId}/diy/v2/collection/${designData.collectionId}/designs/${designData.id}/download_order_buttons?brand_manager=${this.brandManagerValue}&requires_approval=${designData.requiresApproval}&status=APPROVED&template_code=${designData.templateCode}`
      if (this.hasOrderdownloadTarget) {
        this.orderdownloadTarget.src = orderDownloadUrl
      }
    } else if (evt.data.event == "portal.approvalRequestDeclined") {
      let designData = evt.data.design;

      // load the download and order button state
      let orderDownloadUrl = `/${this.companyIdValue}/teams/${designData.teamId}/diy/v2/collection/${designData.collectionId}/designs/${designData.id}/download_order_buttons?brand_manager=${this.brandManagerValue}&requires_approval=${designData.requiresApproval}&status=DECLINED&template_code=${designData.templateCode}`
      if (this.hasOrderdownloadTarget) {
        this.orderdownloadTarget.src = orderDownloadUrl
      }

    } else if (evt.data.event == "portal.approvalRequestCancelled") {
      let designData = evt.data.design;

      // load the download and order button state
      let orderDownloadUrl = `/${this.companyIdValue}/teams/${designData.teamId}/diy/v2/collection/${designData.collectionId}/designs/${designData.id}/download_order_buttons?brand_manager=${this.brandManagerValue}&requires_approval=${designData.requiresApproval}&status=DRAFT&template_code=${designData.templateCode}`
      if (this.hasOrderdownloadTarget) {
        this.orderdownloadTarget.src = orderDownloadUrl
      }

    } else if (evt.data.message == "error.portal.editor_crash") {
      window.location = this.rootPathValue;
    } else if (evt.data == "error.portal.design_not_allowed") {
      window.location = this.rootPathValue;
    } else if (evt.data.event == "portal.design.initialLoad") {
      this.sendClipboard();
    } else if (evt.data.message == "portal.design.loaded") {
      this.sendClipboard();

      let designData = evt.data.design;

      this.titleValue = designData.title
      this.subtitleValue = designData.subtitle
      this.templateCodeValue = designData.templateCode
      this.templateSizeValue = designData.templateSize

      // set the title from the newly selected design
      this.titleTarget.innerHTML = designData.title;

      // update the url to reflect the newly selected design
      let newURL = `/${this.companyIdValue}/teams/${designData.teamId}/diy/v2/collection/${designData.collectionId}/designs/${designData.id}/edit`;
      window.history.pushState({ path: newURL }, "", newURL);

      // load the download and order button state
      let orderDownloadUrl = `/${this.companyIdValue}/teams/${designData.teamId}/diy/v2/collection/${designData.collectionId}/designs/${designData.id}/download_order_buttons`
      if (this.hasOrderdownloadTarget) {
        this.orderdownloadTarget.src = orderDownloadUrl
      }

      // load the action approval button state
      let actionApprovalUrl = `/${this.companyIdValue}/teams/${designData.teamId}/diy/v2/collection/${designData.collectionId}/designs/${designData.id}/action_approval_button`
      if (this.hasActionapprovalTarget) {
        this.actionapprovalTarget.src = actionApprovalUrl
      }
    }
  }
}
